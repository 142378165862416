$(document).ready(function () {
    $('.tab-anchor a').each(function (index, element) {
        $(this).on('click', function (e) {
            e.preventDefault()
            const tabSelector = $(this).closest('.tab-selector');
            if (!$(this).hasClass('selected')) {
                $(tabSelector).find('a.selected').removeClass('selected')
                $(this).addClass('selected');
                const marker = $(tabSelector).find('.marker');
                $(marker).css('left', ($(this).data('id') * $(marker).innerWidth() + 'px'))
                $(tabSelector).closest('.tab').find('.tab-item').each(function (index, value) {
                    $(this).removeClass('active')
                });
                $(this).closest('.tab').find(`.tab-item[data-id='${$(this).data('id')}']`).addClass('active')
            } else
                return
        })
    });
});
